var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',[_c('span',[_vm._v(_vm._s(_vm.label))]),_c('b-input-group',[(_vm.isShowMinusTime)?_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.subtractTime}},[_c('i',{staticClass:"fas fa-minus"})])],1):_vm._e(),_c('flat-pickr',{attrs:{"value":_vm.value,"config":{
          enableTime: _vm.isDateTime,
          enableSeconds: _vm.isDateTime,
          dateFormat: _vm.isDateTime ? 'Y-m-d H:i:S' : 'Y-m-d',
          time_24hr: true,
          altInput: true,
          altFormat: _vm.isDateTime ? 'Y-m-d H:i:S' : 'Y-m-d',
          locale: 'en',
          monthSelectorType: 'dropdown',
          allowInput: true
        }},on:{"input":_vm.updateValue}}),(_vm.isShowAddTime)?_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"success","size":"sm"},on:{"click":_vm.addTime}},[_c('i',{staticClass:"fas fa-plus"})])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }