<template>
  <b-form-group>
    <span>{{ label }}</span>
    <b-input-group>
      <b-input-group-prepend v-if="isShowMinusTime">
        <b-button variant="danger" @click="subtractTime" size="sm">
          <i class="fas fa-minus" />
        </b-button>
      </b-input-group-prepend>
      <flat-pickr
          :value="value"
          :config="{
            enableTime: isDateTime,
            enableSeconds: isDateTime,
            dateFormat: isDateTime ? 'Y-m-d H:i:S' : 'Y-m-d',
            time_24hr: true,
            altInput: true,
            altFormat: isDateTime ? 'Y-m-d H:i:S' : 'Y-m-d',
            locale: 'en',
            monthSelectorType: 'dropdown',
            allowInput: true
          }"
          @input="updateValue"
      />
      <b-input-group-append v-if="isShowAddTime">
        <b-button variant="success" @click="addTime" size="sm">
          <i class="fas fa-plus" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import inputHelper from "@/mixins/utils/inputHelper";

export default {
  name: 'DateTimePicker',
  mixins: [inputHelper],
  components: {
    flatPickr
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    timeUnit: {
      type: String,
      default: 'months'
    },
    isShowAddTime: {
      type: Boolean,
      default: false
    },
    isShowMinusTime: {
      type: Boolean,
      default: false
    },
    isDateTime: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
    addTime() {
      let dateTime = this.momentAdd(this.value, 1, this.timeUnit)
      this.$emit('input', dateTime)
    },
    subtractTime() {
      let dateTime = this.momentAdd(this.value, -1, this.timeUnit)
      this.$emit('input', dateTime)
    }
  }
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
